<template>
    <div class="mainContainer">

        <!-- Quick Links -->
        <div class="d-flex flex-wrap" style="column-gap: 10px">
            <router-link to="/video-wall" class="quickLinkRouter">
                <b-card no-body class="bg-primary quickLinkCard">
                    <h4 style="margin-bottom: 4px">Video Wall</h4>
                    <i class="nav-icon icon-camrecorder" style="font-size: xx-large"/>
                </b-card>
            </router-link>
            <router-link to="/devices" class="quickLinkRouter">
                <b-card no-body class="bg-info quickLinkCard">
                    <h4 style="margin-bottom: 4px">Devices</h4>
                    <i class="nav-icon icon-camera" style="font-size: xx-large"/>
                </b-card>
            </router-link>
            <router-link to="/map-view" class="quickLinkRouter">
                <b-card no-body class="bg-warning quickLinkCard">
                    <h4 style="margin-bottom: 4px">Map View</h4>
                    <i class="nav-icon icon-map" style="font-size: xx-large"/>
                </b-card>
            </router-link>
            <router-link to="/exports" class="quickLinkRouter">
                <b-card no-body class="bg-danger quickLinkCard">
                    <h4 style="margin-bottom: 4px">Exports</h4>
                    <i class="nav-icon icon-folder" style="font-size: xx-large"/>
                </b-card>
            </router-link>
        </div>

        <!-- Widgets -->
        <div class="d-flex flex-wrap" style="gap: 10px; margin-bottom: 10px">

            <!-- Device Status Pie -->
            <DevicesStatusWidget class="widget rounded" style="flex-grow: 3; min-width: 436px"/>

            <!-- Devices Recording/Viewers -->
            <div class="recordingViewersContainer" ref="recordingViewersContainer">
                <DevicesRecordingWidget class="rounded widget" style="flex-grow: 1"/>
                <ViewersWidget class="rounded widget" style="flex-grow: 1"/>
            </div>

            <!-- Storage Usage Pie -->
            <!--<StorageWidget class="widget rounded" style="flex-grow: 3; min-width: 436px"/>-->

            <!-- Update Release Notes -->
            <!--<UpdateNotesWidget class="widget rounded" style="flex-grow: 3; width: 436px"/>-->

        </div>

        <!-- Logo -->
        <div class="flex-grow-1">
            <b-card no-body class="widget logoContainer">
                <img class="card-img fluid logoBackground" src="/img/GeographicWallpaper.jpg" ref="player" alt="Wavy Background"/>
                <div class="logoCenter">
                    <img src="/img/mtoplogo.png" class="brandLogo" alt="Brand Logo">
                </div>
            </b-card>
        </div>

    </div>
</template>

<script>
import eleQueries from "css-element-queries"
import PlayerControls from "@/views/video_wall/stream_components/PlayerControls";
import PieExample from "@/views/template_files/charts/PieExample";
import DevicesStatusWidget from "@/views/template_files/dashboard/DevicesStatusWidget.vue";
import StorageWidget from "@/views/template_files/dashboard/StorageWidget.vue";
import DevicesRecordingWidget from "@/views/template_files/dashboard/DevicesRecordingWidget.vue";
import ViewersWidget from "@/views/template_files/dashboard/ViewersWidget.vue";
import UpdateNotesWidget from "@/views/template_files/dashboard/UpdateNotesWidget.vue";
import deviceStore from "@/store/deviceStore";
import tenantStore from "@/store/tenantStore";
import PubSub from "pubsub-js";

export default {
    name: 'dashboard',

    components: {
        UpdateNotesWidget,
        ViewersWidget,
        DevicesRecordingWidget,
        StorageWidget,
        PlayerControls,
        eleQueries,
        PieExample,
        DevicesStatusWidget
    },
    data() {
        return {
            tenants: [],
            pubsub: null
        }
    },
    async created() {
        this.tenants = await tenantStore.dispatch('getTenants');
        for (const tenant of this.tenants) {
            this.pubsub = PubSub.subscribe('edgeDevicesUpdate', async (msg, data) => {
                if (data.tenantId === tenant._id) {
                    if (data.edgeDevices) {
                        for (const edgeDevice of data.edgeDevices) {
                            await deviceStore.dispatch('getSmartSuiteDevicesByEdgeDevice', {
                                edgeDevice: edgeDevice.name,
                                tenantId: tenant._id,
                                isConnected: true
                            })
                        }
                    }
                }
            });
            await deviceStore.dispatch('setMediahubListener', tenant._id);
        }
    },
    async beforeDestroy() {
        await deviceStore.dispatch('clearSourceListeners');
        PubSub.unsubscribe(this.pubsub);
    }

}
</script>

<style scoped>
.mainContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 207px)
}
.widget {
    background-color: #1a1a1a;
    border: 2px solid white;
}
.recordingViewersContainer {
    min-width: 200px;
    gap: 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
.quickLinkCard {
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}
.quickLinkRouter {
    flex: 1;
    min-width: 200px;
    text-decoration: none;
}
.logoContainer {
    height: 400px;
    position: relative;
    padding: 0;
    margin: 0;
    overflow-y: hidden
}
.logoBackground {
    object-fit: cover;
    margin: 0;
    height: 100%;
    width: 100%
}
.logoCenter {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.brandLogo {
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding: 25px;
    max-width: 1000px
}
</style>
